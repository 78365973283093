








































































import { Component, Vue, PropSync } from "vue-property-decorator";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
@Component({})
export default class dialog_dieta_ficha extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("id_paciente", { type: Number }) syncedId_Paciente!: number;
  @PropSync("id_dieta", { type: Number }) syncedId_Dieta!: number;

  created() {
    dieta_visualizacionModule.getdieta(this.syncedId_Dieta);
  }
  public get dieta() {
    return dieta_visualizacionModule.datos_dieta;
  }

  public GetConfig(id_nutt: number) {
    ConfiguracionModule.getConfiguracionName(id_nutt);
    return "";
  }

  public get nombre_ntt() {
    return ConfiguracionModule.name_ntt;
  }

  public close() {
    this.$emit("close");
    this.syncedDialog = false;
  }
}
